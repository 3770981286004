<template>
  <v-container>
    <v-card class="pa-3" flat dark color="black">
      <div class="orders-box">
        <v-data-iterator
          :items="items"
          :items-per-page.sync="itemsPerPage"
          hide-default-footer
        >
          <!-- <template v-slot:header>
            <v-toolbar
              class="mb-2"
              color="indigo darken-5"
              dark
              flat
            >
              <v-toolbar-title>This is a header</v-toolbar-title>
            </v-toolbar>
          </template> -->

          <template v-slot:default="props">
            <v-row>
              <v-col
                v-for="item in props.items"
                :key="item.name"
                cols="12"
              >
                <v-card class="orders-card">
                  <v-card-title class="subheading font-weight-bold">
                    {{ item.name }}
                  </v-card-title>

                  <v-divider />

                  <v-list dense>
                    <v-list-item>
                      <v-list-item-content>Calories:</v-list-item-content>
                      <v-list-item-content class="align-end">
                        {{ item.calories }}
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>Fat:</v-list-item-content>
                      <v-list-item-content class="align-end">
                        {{ item.fat }}
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>Carbs:</v-list-item-content>
                      <v-list-item-content class="align-end">
                        {{ item.carbs }}
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>Protein:</v-list-item-content>
                      <v-list-item-content class="align-end">
                        {{ item.protein }}
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>Sodium:</v-list-item-content>
                      <v-list-item-content class="align-end">
                        {{ item.sodium }}
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>Calcium:</v-list-item-content>
                      <v-list-item-content class="align-end">
                        {{ item.calcium }}
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>Iron:</v-list-item-content>
                      <v-list-item-content class="align-end">
                        {{ item.iron }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </template>

          <template v-slot:footer>
            <v-toolbar
              class="mt-2"
              color="indigo"
              dark
              flat
            >
              <v-toolbar-title class="subheading">
                This is a footer
              </v-toolbar-title>
            </v-toolbar>
          </template>
        </v-data-iterator>
      </div>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'Invoices',
  data: () => ({
    itemsPerPage: 4,
    items: [
      {
        name: 'Frozen Yogurt',
        calories: 159,
        fat: 6.0,
        carbs: 24,
        protein: 4.0,
        sodium: 87,
        calcium: '14%',
        iron: '1%'
      },
      {
        name: 'Ice cream sandwich',
        calories: 237,
        fat: 9.0,
        carbs: 37,
        protein: 4.3,
        sodium: 129,
        calcium: '8%',
        iron: '1%'
      },
      {
        name: 'Eclair',
        calories: 262,
        fat: 16.0,
        carbs: 23,
        protein: 6.0,
        sodium: 337,
        calcium: '6%',
        iron: '7%'
      },
      {
        name: 'Cupcake',
        calories: 305,
        fat: 3.7,
        carbs: 67,
        protein: 4.3,
        sodium: 413,
        calcium: '3%',
        iron: '8%'
      }
    ]
  })
}
</script>

<style lang="scss" scoped>
.orders-card{
    margin-top:unset;
    margin-bottom: unset;
}
</style>>
